import { get, post } from "@/utils/https";
import qs from "qs";
// 绑定用户微信
export function bindUserInfoWxAcct(data) {
  if (!data.bizNo) throw "缺少bizNo";
  if (!data.openId) throw "缺少openId";
  return post(
    `/api/v1/common/saas/app/offline/apply/customer/wechat/bind`,
    data
  );
}

// 获取第三方平台授权信息
export function getMerchantWxAcctInfo(merchantNo) {
  const param = {
    merchantNo
  };
  return get(
    `/api/v1/common/saas/app/offline/apply/third/auth/info?${qs.stringify(
      param
    )}`
  );
}

export function getWxAuthUrl(merchantNo, redirect_uri) {
  const param = {
    source: "ASSET",
    key: merchantNo,
    redirect_uri: redirect_uri
  };
  return get(`/external/saas/weiXin/third/wechat?${qs.stringify(param)}`);
}
