<template>
  <div class="service-pop">
    <div class="page-wrap">
      <div class="top-bg"></div>
      <div class="bottom-bg"></div>
      <div class="page-main">
        <div class="header">
          <!-- hrefUrl {{ hrefUrl }} code{{ code }} -->

          <div class="sub-tit2">关注公众号</div>
          <div class="l-img"></div>
        </div>

        <div class="s-block b1">
          <p>长按识别下方二维码，关注公众号</p>
          <div class="qr-code-area">
            <img :src="detail.qrCode" alt="" />
          </div>
        </div>

        <div class="s-block b2">
          <p>关注公众号，为您提供还款提醒服务</p>
        </div>
      </div>
      <div class="btns">
        <van-button size="small" @click="goBackDetail">返回</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Notify } from "vant";
import {
  bindUserInfoWxAcct,
  getMerchantWxAcctInfo,
  getWxAuthUrl
} from "@/api/wxAcctBind.js";
export default {
  props: {},
  data() {
    return {
      detail: {}, // 关注公众号
      code: null,
      state: null,
      bizNo: null,
      merchantNo: null
    };
  },
  computed: {
    hrefUrl() {
      return window.location.href;
    },
    showPicker: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      }
    }
  },
  mounted() {
    console.log(location.href);
    // 从详情页带过来的参数
    this.bizNo = this.$route.query?.bizNo ?? undefined;
    this.merchantNo = this.$route.query?.merchantNo ?? undefined;
    this.openId = this.$route.query?.openid ?? undefined;
    console.log(this.openId);
    // 从微信跳转回来的参数
    this.code = this.$route.query?.code ?? undefined;
    this.state = this.$route.query?.state ?? undefined;
    this.initFn();
  },
  methods: {
    async initFn() {
      await this.getMerchantWxAcctInfo();
      if (this.openId) {
        // 微信回调
        this.bindUserInfoWxAcct();
      } else {
        // 没有code则执行绑定
        if (this.detail?.appId) {
          this.wxAuth();
        } else {
          console.log("appId不存在");
        }
      }
    },
    toHexString(url) {
      const encodedUrl = encodeURIComponent(url);
      return Array.from(encodedUrl)
        .map((char) => char.charCodeAt(0).toString(16))
        .join("");
    },
    async wxAuth() {
      const currentUrlWithoutParams =
        window.location.origin + window.location.pathname;
      const h5_redirect_uri = `${currentUrlWithoutParams}?bizNo=${this.bizNo}&merchantNo=${this.merchantNo}`;
      console.log("h5_redirect_uri", h5_redirect_uri);
      // const api_param = {
      //   redirect_uri: this.toHexString(h5_redirect_uri),
      //   key: this.merchantNo
      // };
      // const api_redirect_uri = `https://test.gateway.songchejr.com/external/saas/weiXin/third/wechat/redirect?${qs.stringify(
      //   api_param
      // )}`;
      // const finallyRedirectUri = encodeURIComponent(api_redirect_uri);
      // const appid = this.detail?.appId;
      // const scope = "snsapi_base";
      // const response_type = "code";
      // const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${finallyRedirectUri}&response_type=${response_type}&scope=${scope}&state=STATE#wechat_redirect`;
      // console.log("url", url);
      // window.location.href = url;

      const res = await getWxAuthUrl(this.merchantNo, h5_redirect_uri);
      console.log("接口返回", res.data);
      // console.log(res.data == url);
      window.location.href = res.data;
    },
    async getMerchantWxAcctInfo() {
      try {
        if (!this.merchantNo) return;
        const resp = await getMerchantWxAcctInfo(this.merchantNo);
        this.detail = resp.data || {};
        console.log("this.detail", resp);
      } catch (error) {
        this.$errMsg(error);
      }
    },
    // 绑定用户微信
    async bindUserInfoWxAcct() {
      try {
        await bindUserInfoWxAcct({
          bizNo: this.bizNo,
          openId: this.openId
        });
        Notify({
          type: "success",
          message: "请长按二维码关注公众号",
          duration: 3000
        });
      } catch (error) {
        console.log("我就会大数据时代回家", error);
        this.$errMsg(error);
      }
    },
    goBackDetail() {
      if (this.bizNo) {
        this.$router.push(`/index?bizNo=${this.bizNo}`);
      } else {
        this.$router.go(-1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.service-pop {
  width: 100vw;
  height: 100vh;

  background-color: #d4e9ffff;
  .page-wrap {
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    .top-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 750px;
      height: 922px;
      background-image: url("/images/service/top.png");
      background-position: 0 0;
      background-size: 100% 100%;
    }
    .bottom-bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 750px;
      height: 438px;
      background-image: url("/images/service/bottom.png");
      background-position: 0 0;
      background-size: 100% 100%;
    }
    .page-main {
      height: 100vh;
      width: 100vw;
      position: relative;
      padding: 0 32px;
      .header {
        height: 280px;
        position: relative;
        .tit {
          position: absolute;
          top: 84px;
          width: 332px;
          height: 54px;
          background-image: url("/images/service/tit.png");
          background-position: 0 0;
          background-size: 100% 100%;
        }
        .sub-tit2 {
          position: absolute;
          top: 86px;
          font-weight: bold;
          font-size: 48px;
          color: #333333;

          line-height: 40px;
        }
        .l-img {
          position: absolute;
          top: 38px;
          right: 8px;
          width: 286px;
          height: 252px;
          background-image: url("/images/service/l-img.png");
          background-position: 0 0;
          background-size: 100% 100%;
        }
      }
      .s-block {
        box-shadow: 0px 4px 8px 0px rgba(0, 91, 175, 0.12);
        border-radius: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        position: relative;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: -22px;
          left: 0;
          width: 176px;
          height: 64px;
          background-position: 0 0;
          background-size: 100% 100%;
        }
        &.b1 {
          width: 686px;
          height: 790px;
          margin-top: 20px;
          &::before {
            background-image: url("/images/service/step-1.png");
          }
        }
        &.b2 {
          width: 686px;
          height: 192px;
          margin-top: 54px;
          &::before {
            background-image: url("/images/service/step-2.png");
          }
        }
        p {
          margin-top: 88px;
          font-weight: 600;
          font-size: 28px;
          color: #333333;
          line-height: 28px;
          margin-bottom: 48px;
        }
        .qr-code-area {
          width: 576px;
          height: 576px;
          border: 2px solid #1890ff33;
          border-radius: 16px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 8px;
          }
        }
      }
    }
    .btns {
      position: fixed;
      top: 20px;
      right: 32px;
      z-index: 1000;

      border-top: 1px solid #efefef;
      height: 100px;

      box-shadow: 0 -5px 10px #efefef;
      .van-button {
        min-width: 160px;
      }
    }
  }
}
</style>
